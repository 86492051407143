import {
  computed,
  Component,
  ElementRef,
  NgZone,
  inject,
  numberAttribute,
  booleanAttribute,
  input,
  signal,
  afterNextRender,
  isDevMode,
  ViewEncapsulation,
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  type InputSignal,
  type InputSignalWithTransform
} from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { explicitEffect, fadeAnimations, FaIconWrapperComponent, getDefaultWindow, NOOP_ANIMATION_MODE } from '@vermeer-corp/it-ng-components/core';

/** Config used to bind passive event listeners. */
const _passiveEventOptions =  normalizePassiveListenerOptions({ passive: true });

/**
 * `VmrBackToTop` is a button that displays in the lower right corner of the screen when `threshold` is surpassed.
 * Used to return user to the top of the window or specified scrollable element using specified `scrollBehavior`.
 */
@Component({
  standalone: true,
  selector: 'vmr-back-to-top',
  styleUrl: 'back-to-top.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimations.fadeInOut],
  imports: [FaIconWrapperComponent],
  template: `
    @if (showButton()) {
      <button
        @fadeInOut
        type="button"
        title="Back to top"
        (click)="handleClick()"
        [style.bottom.px]="bottomPx()"
        class="vmr-back-to-top-button"
        [class.vmr-noop-animation]="_animationsDisabled"
      >
        <fa-icon-wrapper [icon]="faIcon()" />
      </button>
    }
  `,
  host: {
    class: 'vmr-back-to-top'
  }
})
export class VmrBackToTop {
  private readonly _ngZone = inject(NgZone);
  private readonly _window = getDefaultWindow();

  /** Parent element for which we want to monitor scroll events on. */
  readonly target = input<ElementRef<HTMLElement>>();

  /** The icon to render on `fa-icon`. Defaults to `chevrons-up`. */
  readonly faIcon: InputSignal<IconProp> = input<IconProp>('angle-up');

  /** The `ScrollBehavior` for the triggered scroll event. Defaults to `smooth`. */
  readonly scrollBehavior: InputSignal<ScrollBehavior> = input<ScrollBehavior>('smooth');

  /** The scroll delta (in pixels) before the button will show. Defaults to `200`. */
  readonly threshold: InputSignalWithTransform<number, unknown> = input(200, {
    transform: numberAttribute
  });

  /**
   * Some Vermeer apps show an Intercom button in the same location for production environments.
   * When `intercomOffset` is `true` and `isDevMode()` is `false` the `bottom` CSS property is overriden with `80px`.
   */
  readonly intercomOffset: InputSignalWithTransform<boolean, unknown> = input(false, {
    transform: booleanAttribute
  });

  /** @internal */
  protected _animationsDisabled: boolean;

  /** @internal */
  readonly showButton = signal<boolean>(false);

  /** @internal */
  readonly bottomPx = computed<number | null>(() => this.intercomOffset() && !isDevMode() ? 80 : null);

  /** @internal */
  get scrollElement(): HTMLElement | (Window & typeof globalThis) {
    return this.target()?.nativeElement ?? this._window;
  }

  constructor() {
    explicitEffect(
      [this.target],
      ([target], onCleanup) => {
        target && this._addEventListener();
        onCleanup(() => this._removeEventListener());
      },
      {defer: true}
    );

    afterNextRender(() => this._addEventListener());
    this._animationsDisabled = inject(ANIMATION_MODULE_TYPE, {optional: true}) === NOOP_ANIMATION_MODE;
  }

  /** The click event handler for the "back to top" button. */
  handleClick(): void {
    this.scrollElement?.scroll({
      top: 0,
      behavior: this.scrollBehavior()
    });
  }

  private _checkVisibility(scrollY: number): void {
    const showButton = scrollY > this.threshold();
    if (this.showButton() !== showButton) {
      this._ngZone.run(() => this.showButton.set(showButton));
    }
  }

  private _removeEventListener(): void {
    const target = this.scrollElement;
    target?.removeEventListener('scroll', this._getScrollEventListener(target));
  }

  private _addEventListener(): void {
    this._ngZone.runOutsideAngular(() => {
      const target = this.scrollElement;
      target?.addEventListener('scroll', this._getScrollEventListener(target), _passiveEventOptions);
    });
  }

  private _getScrollEventListener(target: HTMLElement | (Window & typeof globalThis)): (() => void) {
    return target instanceof HTMLElement ? this._onParentScrollListener : this._onWindowScrollListener;
  }

  private _onParentScrollListener = (): void => {
    const {scrollTop = 0} = this.target()?.nativeElement ?? {};
    this._checkVisibility(scrollTop);
  };

  private _onWindowScrollListener = (): void => {
    const {scrollTop, clientTop = 0} = document.documentElement;
    const scrollY = (this._window.scrollY || scrollTop) - clientTop;
    this._checkVisibility(scrollY);
  };
}
