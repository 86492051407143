import type { ToastPosition, VmrToast } from '../types';
import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toastFilter'
})
export class ToastFilterPipe implements PipeTransform {
  transform(
    toasts: VmrToast[],
    index: number,
    position: ToastPosition
  ): VmrToast[] {
    return toasts.filter(t => (!t.position && index === 0) || t.position === position);
  }
}